.container-module .global-search-modal {
position:absolute;top:var(--menu-bar-height);left:calc(50% - 205px);display:flex;justify-content:center;padding-top:var(--spacing-lg)
}

.container-module .global-search-modal .global-search-body {
z-index:calc(var(--z-index-overlay) + 1);width:410px;height:410px;padding:var(--spacing-md) var(--spacing-sm) 0 var(--spacing-md);background-clip:padding-box;border:1px solid color-mix(in srgb, var(--color-gray800) 5%, transparent);box-shadow:var(--shadow-2xlg)
}

.container-module .global-search-modal .global-search-body .global-search-body-header {
overflow-y:hidden;scrollbar-gutter:stable
}

.container-module .global-search-modal .global-search-body .global-search-list {
height:100%;padding:var(--spacing-md) 0;overflow-y:auto;scrollbar-gutter:stable
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-header {
box-sizing:border-box;margin-inline-start:var(--spacing-md);margin-top:var(--spacing-xs);margin-bottom:var(--spacing-sm);text-wrap:wrap
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item {
box-sizing:border-box;min-height:32px;padding:var(--spacing-xxs) var(--spacing-md);margin-bottom:var(--spacing-xs);text-wrap:wrap;border-radius:8px
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item .global-search-list-item-text {
color:color-mix(in srgb, var(--color-gray800) 80%, transparent)
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item.global-search-modal-list-item-highlighted,.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item:hover {
cursor:pointer;background-color:color-mix(in srgb, var(--color-gray800) 5%, transparent)
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item.global-search-modal-list-item-highlighted .global-search-list-item-text,.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item:hover .global-search-list-item-text {
color:var(--color-gray800)
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item.global-search-modal-list-item-highlighted .favorite-icon,.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item:hover .favorite-icon {
visibility:visible
}

.container-module .global-search-modal .global-search-body .global-search-list .global-search-modal-list-item:active {
cursor:pointer;background-color:color-mix(in srgb, var(--color-gray800) 10%, transparent)
}

