.container-module .favorite-icon {
margin-inline-start:var(--spacing-sm);visibility:hidden
}

.container-module .favorite-icon.favorite-icon-star-full {
color:var(--foreground-subtle)
}

.container-module .favorite-icon.favorite-icon-star-full:hover {
color:var(--foreground-minimal)
}

.container-module .favorite-icon.favorite-icon-star-outline {
color:var(--foreground-minimal)
}

.container-module .favorite-icon.favorite-icon-star-outline:hover {
color:var(--foreground-subtle)
}

